import React, { useEffect, useState } from "react"

import AllEvents from "./AllEvents"
import Button from "../../style/Button"
import FilterButton from "../../features/FilterButton"
import Flex from "../../style/Flex"
import { Link } from "raviger"
import MyEvents from "./MyEvents"
import useSWR from "swr"
import { x } from "@xstyled/styled-components"

type Props = {}

const EventOverview: React.FC<Props> = () => {
  const { data: user } = useSWR<User>("auth/me", { suspense: false })
  const { data: fighters } = useSWR<Fighter[]>("users/fighters", {
    suspense: false,
  })
  const [selected, setSelected] = useState<Filters>("mine")

  useEffect(() => {
    if (user) setSelected("mine")
    else setSelected(null)
    if (fighters?.length === 0) setSelected(null)
  }, [fighters, user])

  return (
    <div>
      {user && (
        <Flex
          justifyContent={{ xs: "center", md: "space-between" }}
          flexWrap="wrap"
        >
          <x.div mb={{ xs: 2, md: 0 }}>
            <FilterButton showMine selected={selected} onSelect={setSelected} />
          </x.div>
          {user?.isAdmin && (
            <Link href="events/create">
              <Button variant="primary">Opret begivenhed</Button>
            </Link>
          )}
        </Flex>
      )}
      {selected !== "mine" || !user ? (
        <AllEvents filter={selected} />
      ) : (
        <MyEvents />
      )}
    </div>
  )
}

export default EventOverview
