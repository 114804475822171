import * as React from 'react'

class ErrorBoundary extends React.Component {
  state : {hasError: boolean, error: React.ErrorInfo | null} = { hasError: false, error: null }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log(error)
    console.log(errorInfo)
    this.setState({error: errorInfo})
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong.</h1>
          <p>{this.state.error?.componentStack}</p>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
