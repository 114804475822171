import React, { useMemo } from "react"

import Category from "./Category"
import { ErrorText } from "../../style/Text"
import Loading from "../../features/Loading"
import useSWR from "swr"

type GroupedData = {
  aspirant: Tournament[]
  cadet: Tournament[]
  junior: Tournament[]
  senior: Tournament[]
  empty: Tournament[]
}

type Props = { filter: AgeCategory | null | "mine" }

const AllEvents: React.FC<Props> = ({ filter }) => {
  const { data: rawData, error } = useSWR<Tournament[]>("event")
  const data = useMemo(
    () =>
      (rawData || [])
        .sort((a, b) => a.start.getTime() - b.start.getTime())
        .reduce<GroupedData>(
          (memo, t) => {
            if (t.category.includes("aspirant")) {
              memo.aspirant.push(t)
            }
            if (t.category.includes("cadet")) {
              memo.cadet.push(t)
            }
            if (t.category.includes("junior")) {
              memo.junior.push(t)
            }
            if (t.category.includes("senior")) {
              memo.senior.push(t)
            }
            if (t.category.includes("")) {
              memo.empty.push(t)
            }
            return memo
          },
          { aspirant: [], cadet: [], junior: [], senior: [], empty: [] },
        ),
    [rawData],
  )

  const shouldShow = (category: string): boolean => {
    if (filter === null) return true
    return filter === category
  }

  if (error) return <ErrorText mt="6">{error.message}</ErrorText>
  if (!data) return <Loading />

  return (
    <>
      {shouldShow("aspirant") && (
        <Category
          title="Udviklingsgruppe"
          events={data.aspirant}
          forceShowAll={filter === "aspirant"}
        />
      )}
      {shouldShow("cadet") && (
        <Category
          title="Kadetter"
          events={data.cadet}
          forceShowAll={filter === "cadet"}
        />
      )}
      {shouldShow("junior") && (
        <Category
          title="Juniorer"
          events={data.junior}
          forceShowAll={filter === "junior"}
        />
      )}
      {shouldShow("senior") && (
        <Category
          title="Seniorer"
          events={data.senior}
          forceShowAll={filter === "aspirant"}
        />
      )}
      {shouldShow("") && (
        <Category
          title="Ukategoriseret"
          events={data.empty}
          forceShowAll={true}
        />
      )}
    </>
  )
}

export default AllEvents
