import styled from "@xstyled/styled-components"

const Title = styled.h2`
  margin: 0;
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 5;
  margin: 2 0;
`
export const Subtitle = styled.h2`
  margin: 0;
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 4;
  margin: 2 0;
`

export default Title
