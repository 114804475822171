import Dialog, { CloseButton, Header } from "../../style/Dialog"
import React, { useEffect, useState } from "react"

import Button from "../../style/Button"
import DeleteModal from "./DeleteModal"
import { ErrorText } from "../../style/Text"
import Field from "../../style/Field"
import Flex from "../../style/Flex"
import { put } from "../../utils/request"
import useSWR from "swr"
import { x } from "@xstyled/styled-components"

type Props = { onClose: () => void }

const defaultState = {
  name: "",
  email: "",
  phoneNumber: "",
}

const EditUserModal: React.FC<Props> = ({ onClose }) => {
  const { data, mutate } = useSWR<User>("auth/me")
  const [state, setState] = useState(defaultState)
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [showDelete, setShowDelete] = useState(false)

  useEffect(() => {
    if (data) {
      const { name, email, phoneNumber } = data
      setState({
        name,
        email,
        phoneNumber,
      })
    }
  }, [data])

  const handleChange = (name: string, e: string) => {
    setState((s) => ({ ...s, [name]: e }))
  }

  const handleSubmit = async (): Promise<void> => {
    try {
      setInProgress(true)

      await put(`users`, state)
      mutate((u) => ({ ...u, ...state }))
      setInProgress(false)
      onClose()
    } catch (error) {
      setInProgress(false)
      setError(error)
      console.log(error)
    }
  }

  return (
    <Dialog
      aria-label="delete user dialog"
      onDismiss={inProgress ? undefined : onClose}
    >
      <x.div display="flex" justifyContent="space-between">
        <Header>Ret bruger</Header>
        {!inProgress && <CloseButton onClick={onClose} />}
      </x.div>
      <x.div p="3">
        <Field
          label="Navn"
          id="name"
          value={state.name}
          onChange={handleChange}
        />
        <Field
          label="E-mail"
          id="email"
          value={state.email}
          onChange={handleChange}
        />
        <Field
          label="Telefonnummer"
          id="phoneNumber"
          value={state.phoneNumber}
          onChange={handleChange}
        />

        <x.div mt="4" display="flex" justifyContent="space-between">
          <Button variant="link" onClick={() => setShowDelete(true)}>
            Slet bruger
          </Button>

          <Flex>
            <Button variant="link" onClick={onClose} disabled={inProgress}>
              Fortryd
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              loading={inProgress}
            >
              Gem
            </Button>
          </Flex>
        </x.div>
        {error && <ErrorText mt="3">{error?.message}</ErrorText>}
        {showDelete && <DeleteModal onClose={() => setShowDelete(false)} />}
      </x.div>
    </Dialog>
  )
}

export default EditUserModal
