import { MdClose, MdEmail } from "react-icons/md"
import React, { useState } from "react"
import { Th, Tr, Table as WideTable } from "../../../style/table"
import styled, { useDown, x } from "@xstyled/styled-components"

import DeleteRegistrationDialog from "../DeleteRegistrationDialog"
import { Subtitle } from "../../../style/Title"
import Text from "../../../style/Text"

type Props = {
  fighters: Fighter[]
  eventUid: string
  title?: string
  eventTitle?: string
  attending: boolean
  canDeleteFighter: boolean
  onSendMail?: () => void
  isAdmin?: boolean
}
type Fighter = {
  uid: string
  name: string
  club: string
  ageCategory?: string
  status?: string
  birthYear?: number
  custom: { name: string; value: string }[]
}

type DeleteFighter = {
  fighterUid: string
  fighterName: string
}

const Remove = styled.button`
  color: primary;
  background-color: transparent;
  border: none;
  padding: 1rem;
  margin: 0 1rem;
  cursor: pointer;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: text;
  cursor: pointer;
  transition: color 300ms;
  :hover {
    color: primary;
  }
`

const Name = styled.h3`
  margin: 0;
  font-size: 3;
  font-weight: normal;
`

const getHeaders = (fighters: Fighter[]): string[] =>
  fighters.reduce((total: string[], fighter) => {
    fighter.custom.forEach((c) => {
      if (!total.some((headers) => headers === c.name)) {
        total.push(c.name)
      }
    })
    return total
  }, [])

const Table: React.FC<Props> = ({
  fighters,
  title = "",
  onSendMail,
  eventUid,
  eventTitle,
  attending,
  isAdmin = false,
  canDeleteFighter,
}) => {
  const downMd = useDown("md")
  const [deleteFighter, setDeleteFighter] = useState<DeleteFighter | null>(null)
  const headers = getHeaders(fighters || [])

  const handleDeleteFighter = (fighterUid: string, fighterName: string) => {
    setDeleteFighter({ fighterUid, fighterName })
  }

  const renderDesktopRow = ({
    uid,
    name,
    birthYear,
    custom,
    club,
  }: Fighter) => {
    return (
      <Tr key={uid}>
        {isAdmin && <td>{birthYear}</td>}
        <td>{name}</td>
        <td>{club}</td>
        {headers.map((header, index) => {
          const val = custom.find((custom) => custom.name === header)
          return <td key={index}>{val && val.value}</td>
        })}

        {isAdmin && canDeleteFighter && (
          <td>
            <x.div display="flex" justifyContent="flex-end">
              <Remove onClick={() => handleDeleteFighter(uid, name)}>
                <MdClose />
              </Remove>
            </x.div>
          </td>
        )}
      </Tr>
    )
  }

  const renderDesktop = () => {
    if (fighters.length === 0) return null

    return (
      <WideTable>
        <thead>
          <tr>
            {isAdmin && <Th width="100px">Årgang</Th>}
            <Th width="30%">Navn</Th>
            <Th>Klub</Th>

            {headers.map((h) => (
              <Th key={h}>{h}</Th>
            ))}
            {isAdmin && canDeleteFighter && <Th />}
          </tr>
        </thead>
        <tbody>{fighters?.map(renderDesktopRow)}</tbody>
      </WideTable>
    )
  }

  const renderMobileRow = ({ uid, name, birthYear, custom, club }: Fighter) => {
    return (
      <x.div
        key={uid}
        borderBottom="1px solid"
        px="2"
        pb="2"
        pt="3"
        borderColor="border"
      >
        <x.div display="flex" justifyContent="space-between">
          <Name>{`${name}${birthYear ? ` (${birthYear})` : ""}`}</Name>

          {isAdmin && canDeleteFighter && (
            <Remove onClick={() => handleDeleteFighter(uid, name)}>
              <MdClose />
            </Remove>
          )}
        </x.div>
        <Text m="0">{club}</Text>

        <x.div color="faded" m="0" pl="2">
          {custom.map((f) => (
            <x.div my="1" key={f.name}>
              {`${f.name}: `} <i>{f.value}</i>
            </x.div>
          ))}
        </x.div>
      </x.div>
    )
  }
  const renderMobile = () => {
    if (fighters.length === 0) return null

    return <>{fighters?.map(renderMobileRow)}</>
  }

  return (
    <>
      <x.div py="3">
        <x.div
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Subtitle>
            {title} ({fighters.length})
          </Subtitle>
          {isAdmin && !!fighters.length && (
            <Button onClick={onSendMail}>
              <MdEmail size="24px" />
            </Button>
          )}
        </x.div>
        {downMd ? renderMobile() : renderDesktop()}
      </x.div>
      {deleteFighter && (
        <DeleteRegistrationDialog
          tournamentName={eventTitle || ""}
          eventUid={eventUid}
          onClose={() => setDeleteFighter(null)}
          attending={attending}
          {...deleteFighter}
        />
      )}
    </>
  )
}

export default Table
