import React, { useEffect, useState } from "react"

import Loading from "../Loading"
import Login from "./Login"
import Register from "./Register"
import Text from "../../style/Text"
import { navigate } from "raviger"
import { post } from "../../utils/request"
import styled from "@xstyled/styled-components"
import useSWR from "swr"

type Props = { invite: string }

const Grid = styled.div`
  padding-top: 10vh;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto;
  grid-gap: 6;
  align-items: center;
`
const Divider = styled.div`
  border-left: 2px solid;
  border-color: primary;
  height: 100%;
`

const Invite: React.FC<Props> = ({ invite }) => {
  const { data, error } = useSWR("auth/me")
  const [inviteError, setInviteError] = useState<string | null>(null)

  useEffect(() => {
    const init = async () => {
      try {
        await post(`auth/connect/${invite}`)
        navigate("/")
      } catch (error) {
        console.log(error)

        setInviteError(error.message)
      }
    }

    if (invite && data) init()
  }, [data, invite])

  if (error?.statusCode === 403) {
    return (
      <Grid>
        <Register />
        <Divider />
        <Login />
      </Grid>
    )
  }
  if (inviteError) return <Text>{inviteError}</Text>

  return <Loading />
}

export default Invite
