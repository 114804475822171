import React, { InputHTMLAttributes } from 'react'
import styled from '@xstyled/styled-components'
import Input from './Input'
import Label from './Label'

type Props = {
  label: string
  id: string
  onChange: (name: string, value: string) => void
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>

const Item = styled.div`
  display: flex;
  flex-direction: column;
`

const Field: React.FC<Props> = ({ id, label, onChange, ...rest }) => {
  return (
    <Item>
      <Label htmlFor={id}>{label}</Label>
      <Input
        id={id}
        onChange={e => onChange(id, e.currentTarget.value)}
        {...rest}
      />
    </Item>
  )
}

export default Field
