import styled, { x } from "@xstyled/styled-components"

import { system } from "@xstyled/system"

const Text = styled("p")(system)

const StyledError = styled.p`
  margin: 0;
  text-align: center;
  color: error;
`

export const ErrorText = styled(StyledError)(system) as typeof x.p

export default Text as typeof x.p
