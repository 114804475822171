const dateTimeFormat: any = new Intl.DateTimeFormat("da", {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
})

const formatDateTime = (date: Date): string => dateTimeFormat.format(date)

export default formatDateTime
