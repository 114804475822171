import React from "react"
import Title from "../../style/Title"
import styled from "@xstyled/styled-components"

type Props = {}
const Text = styled.p`
  white-space: pre-line;
`
const Persondatapolitik: React.FC<Props> = () => {
  return (
    <>
      <Title>Persondatapolitik hos Dansk Judo og Ju-Jitsu Union</Title>
      <Text>{`
    Vi indhenter kun persondata i de tilfælde, hvor dette skulle være relevant for os, og vi vil kun indhente persondata, hvis det er relevant for din aktivitet hos Dansk Judo og Ju-Jitsu Union.
    
    Ved indsamling, behandling og anvendelse af dine persondata overholder vi altid alle relevante lovbestemmelser.
    
    Vi vil kun opbevare dine persondata, så længe vi enten er pålagt en juridisk forpligtelse hertil, eller så længe det er relevant for den hensigt, hvormed de blev indsamlet.
    
    
    
    Oplysninger vi indsamler
    
    Hvis du ønsker at modtage en ydelse fra os, har vi brug for at indsamle visse persondata for at kunne gennemføre handlen og for at kunne tilbyde dig vores services. Vi kan bruge cookies til at holde styr på indholdet i din session, mens du bruger vores side.
    
    Vi kan indhente oplysninger som f.eks. navn, e-mailadresse, telefonnummer.
    
    Dansk Judo og Ju-Jitsu Union indsamler og behandler dine persondata, når du foretager dig følgende:
    
    
    - Besøger vores hjemmeside
    
    - Opretter en konto
    
    
    
    Den dataansvarlige
    
    Den dataansvarlige for indsamling, behandling og anvendelse af dine personoplysninger på landsholdet.judo.dk er Dansk Judo og Ju-Jitsu Union, Brøndby Stadion 20, 2605 Brøndby, cvr nr. 53017312
    
    
    
    Behandlingsgrundlag og formål
    
    Dine almindelige kontaktoplysninger indhenter vi for at kunne sende dig en påmindelser om turneringer samt information relevant for landsholdet.
    
    
    
    
    Andre modtagere af personoplysninger
    
    Vi sælger ikke dine persondata til tredjemand, og vi overfører ikke dine persondata til tredjelande.
    
    Vi har vores hjemmeside hos DigitalOcean , som fungerer som vores databehandler. Alle persondata som du oplyser på vores hjemmeside vil blive opbevaret i DigitalOceans datacentre.
    
    Vi anvender eksterne virksomheder som leverandører for at levere vores ydelser bedst muligt. Disse eksterne leverandører er databehandlere og behandler i visse tilfælde personoplysninger i forbindelse med deres levering af ydelser til os. Vores databehandlere behandler kun personoplysninger efter vores instruks og i overensstemmelse med lovgivningens krav til databehandlere.
    
    Vi har indgået databehandleraftaler med vores databehandlere, hvilket er vores garanti for, at de overholder gældende regler om beskyttelse af dine personoplysninger.
    
    
    
    Dine rettigheder
    
    Som den registrerede har du en række rettigheder, som vi til enhver tid skal sikre opfyldelse af. Du har ret til at anmode os om følgende:
    • At få adgang til og få rettet/ændret dine persondata
    • At få slettet persondata
    
    Du har derudover ret til at protestere over behandlingen af dine personlige data, og du har ret til at indgive klage til en databeskyttelsesmyndighed.
    
    Ønsker du ikke længere, at vi skal behandle dine personoplysninger, eller at vi skal begrænse behandlingen af dine personoplysninger, kan du også sende os en anmodning herom til vores e-mailadresse dju@dju.dk.
    
     `}</Text>
    </>
  )
}

export default Persondatapolitik
