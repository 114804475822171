import Dialog, { CloseButton, Header } from "../../style/Dialog"
import React, { useState } from "react"

import Button from "../../style/Button"
import { ErrorText } from "../../style/Text"
import InfoBox from "../../style/InfoBox"
import { del } from "../../utils/request"
import { mutate } from "swr"
import { x } from "@xstyled/styled-components"

type Props = {
  onClose: () => void
  eventUid: string
  fighterUid: string
  fighterName: string
  tournamentName: string
  attending?: boolean
}

const DeleteRegistrationDialog: React.FC<Props> = ({
  fighterName,
  fighterUid,
  tournamentName,
  onClose,
  eventUid,
  attending,
}) => {
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const handleDelete = async () => {
    try {
      setInProgress(true)

      await del(`event/${eventUid}/rsvp/${fighterUid}`)

      mutate(`event/${eventUid}/fighters`)
      mutate(`event/${eventUid}/admin/registrations`)
      mutate(`event/${eventUid}/registrations`)
      setInProgress(false)
      onClose()
    } catch (error) {
      setError(error)
      setInProgress(false)
    }
  }

  const status = attending ? "tilmelding" : "framelding"

  return (
    <Dialog aria-label="sign up" onDismiss={inProgress ? undefined : onClose}>
      <x.div display="flex" justifyContent="space-between">
        <Header> {`Slet ${status}?`}</Header>
        {!inProgress && <CloseButton onClick={onClose} />}
      </x.div>
      <InfoBox my="3" mx="4">
        <p>
          {`
          Er du sikker på du vil slette ${fighterName}' ${status} til ${tournamentName}?
            `}
        </p>
        <p>Du kan efterfølgende tilmelde eller framelde {fighterName} igen.</p>
      </InfoBox>
      <x.div p="3" display="flex" justifyContent="flex-end">
        <Button mr="3" variant="link" onClick={onClose} disabled={inProgress}>
          Fortryd
        </Button>
        <Button variant="primary" onClick={handleDelete} loading={inProgress}>
          Slet
        </Button>
      </x.div>
      {error && <ErrorText>{error?.message}</ErrorText>}
    </Dialog>
  )
}

export default DeleteRegistrationDialog
