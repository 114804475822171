import Flex from "../style/Flex"
import React from "react"
import styled from "@xstyled/styled-components"

const BaseButton = styled("button")<{ selected: boolean }>`
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
  padding: 0.375rem 0.625rem;
  border: none;
  margin: 0;

  background-color: ${(p) =>
    p.selected ? "transparent" : p.theme.colors.basic};
  color: ${(p) => (p.selected ? p.theme.colors.primary : "black")};

  transition: color 250ms, background-color 250ms;

  :first-child {
    border-radius: 0.25rem 0 0 0.25rem;
  }

  :last-child {
    border-radius: 0 0.25rem 0.25rem 0;
  }
`

export type Props = {
  onSelect: (value: Filters) => void
  selected: Filters
  showMine?: boolean
}
export const FilterButton: React.FC<Props> = ({
  selected,
  onSelect,
  showMine,
}) => {
  return (
    <Flex>
      {showMine && (
        <BaseButton
          selected={selected === "mine"}
          onClick={() => onSelect("mine")}
        >
          Mine
        </BaseButton>
      )}
      <BaseButton selected={selected === null} onClick={() => onSelect(null)}>
        Alle
      </BaseButton>
      <BaseButton
        selected={selected === "aspirant"}
        onClick={() => onSelect("aspirant")}
      >
        Udviklingsgruppe
      </BaseButton>
      <BaseButton
        selected={selected === "cadet"}
        onClick={() => onSelect("cadet")}
      >
        Kadet
      </BaseButton>
      <BaseButton
        selected={selected === "junior"}
        onClick={() => onSelect("junior")}
      >
        Junior
      </BaseButton>
      <BaseButton
        selected={selected === "senior"}
        onClick={() => onSelect("senior")}
      >
        Senior
      </BaseButton>
    </Flex>
  )
}

export default FilterButton
