import Dialog, { CloseButton, Header } from "../../style/Dialog"
import React, { useState } from "react"

import Button from "../../style/Button"
import { ErrorText } from "../../style/Text"
import InfoBox from "../../style/InfoBox"
import { del } from "../../utils/request"
import { x } from "@xstyled/styled-components"

type Props = {
  onClose: () => void
}

const DeleteModal: React.FC<Props> = ({ onClose }) => {
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const handleDelete = async () => {
    try {
      setInProgress(true)
      await del("users/me")
      window.location.replace("/")
    } catch (error) {
      setInProgress(false)
      setError(error)
    }
  }

  return (
    <Dialog
      aria-label="delete user dialog"
      onDismiss={inProgress ? undefined : onClose}
    >
      <x.div display="flex" justifyContent="space-between">
        <Header>Slet bruger</Header>
        {!inProgress && <CloseButton onClick={onClose} />}
      </x.div>
      <x.div p="3">
        <InfoBox>
          Kæmpere, der er tilknyttet profilen vil ikke blive slettet
          <br />
          Er du sikker på du vil slette din bruger?
          <br />
          <br />
          Denne handling kan ikke fortrydes!
        </InfoBox>

        <x.div mt="4" display="flex" justifyContent="flex-end">
          <Button variant="link" onClick={onClose} disabled={inProgress}>
            Fortryd
          </Button>
          <Button variant="primary" onClick={handleDelete} loading={inProgress}>
            Slet bruger
          </Button>
        </x.div>
        {error && <ErrorText>{error?.message}</ErrorText>}
      </x.div>
    </Dialog>
  )
}

export default DeleteModal
