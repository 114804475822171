import { LayoutProps, SpaceProps, layout, space } from "@xstyled/system"
import { assign, getThemeValue, is, merge, warn } from "@xstyled/util"
import styled, { css, keyframes } from "@xstyled/styled-components"

import React from "react"

const variant =
  ({ key = null, default: defaultValue, variants = {}, prop = "variant" }) =>
  (props) => {
    const themeVariants = is(key) ? getThemeValue(props, key) : null
    const computedVariants = merge(assign({}, variants), themeVariants)
    const value = props[prop] !== undefined ? props[prop] : defaultValue
    const result = getThemeValue(props, value, computedVariants)
    warn(is(result), `variant "${value}" not found`)
    return result
  }

type Variant = "primary" | "secondary" | "link"

type BaseProps = SpaceProps &
  LayoutProps & {
    variant?: Variant
  }

const skFlow = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0.3);
  }
  40% {
    transform: scale(1);
  
}`

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  /* box-sizing: border-box; */
`
const Flow = styled.div<{ color?: string }>`
  width: 65px;
  padding: 0 15px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .sk-flow-dot {
    width: 50%;
    height: 50%;
    border-radius: 100%;
    animation: ${skFlow} 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s
      infinite both;
  }

  .sk-flow-dot:nth-child(1) {
    animation-delay: -0.3s;
  }
  .sk-flow-dot:nth-child(2) {
    animation-delay: -0.15s;
  }
`
const Text = styled.div<{ show: boolean }>`
  transition: opacity 200ms;
  opacity: ${(p) => (p.show ? 1 : 0)};
`

const Loader: React.FC = () => {
  return (
    <Wrapper>
      <Flow>
        <div className="sk-flow-dot"></div>
        <div className="sk-flow-dot"></div>
        <div className="sk-flow-dot"></div>
      </Flow>
    </Wrapper>
  )
}

const BaseButton = styled("button")<BaseProps>`
  position: relative;
  display: inline-block;
  z-index: 0;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
  padding: 0.375rem 0.625rem;
  border-radius: 0.25rem;
  border-width: 0px;
  transition: color 300ms ease 0s, border-style, border-color, visibility,
    background, background-color, text-decoration, box-shadow, transform,
    opacity;
  text-decoration: none;
  :disabled {
    cursor: not-allowed;
  }
  ${space}
  ${layout}
  ${variant({
    default: "basic",
    variants: {
      basic: css`
        background-color: basic;
        color: black;

        .sk-flow-dot {
          background-color: black;
        }
      `,
      primary: css`
        background-color: primary;
        color: white;
        .sk-flow-dot {
          background-color: white;
        }
      `,
      secondary: css`
        background-color: secondary;
        color: white;
        .sk-flow-dot {
          background-color: white;
        }
      `,
      link: css`
        background-color: transparent;
        color: primary;
        :disabled {
          color: faded;
        }
        .sk-flow-dot {
          background-color: primary;
        }
      `,
    },
  })}
`

type Props = { loading?: boolean } & BaseProps &
  React.HTMLProps<HTMLButtonElement>

const Button: React.FC<Props> = (props) => {
  const { loading, children, onClick, ...rest } = props as any
  return (
    <BaseButton onClick={loading ? undefined : onClick} {...rest}>
      {loading && <Loader />}
      <Text show={!loading}>{children}</Text>
    </BaseButton>
  )
}

export default Button
