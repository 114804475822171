import styled from "@xstyled/styled-components"

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`
export const Th = styled.th<{ width?: string }>`
  margin: 0;
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 2;
  text-align: start;
  width: ${(p) => p.width || "auto"};
`
export const Tr = styled.tr<{ cursor?: string }>`
  border-bottom: 1px solid #f2f2f2;
  height: 50px;
  cursor: ${(p) => p.cursor || "auto"};
`
