import { Link as A, usePath } from "raviger"
import { breakpoints, down } from "@xstyled/system"
import styled, { css, useUp, x } from "@xstyled/styled-components"

import React from "react"
import logo from "../assets/logo.svg"
import useSWR from "swr"

const HEADER_HEIGHT = 60

const MenuItem = styled.nav(
  breakpoints({
    xs: css`
      font-size: 1;
      color: white;
      margin: 0 2;
      text-decoration: none;
      text-transform: uppercase;
      transform: translateY(2px);
    `,
    sm: css`
      font-size: 2;
      margin: 0 3;
    `,
  }),
)
const MenuBox = styled(A)<{ selected?: boolean }>`
  height: 100%;
  color: white;
  border-bottom: 4px solid;
  border-color: ${(p) => (p.selected ? "white" : "transparent")};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
  margin-bottom: -2px;
  :active {
    text-decoration: none;
  }
`
const Top = styled.div`
  color: white;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  background-color: header;
  position: fixed;
  display: flex;
  align-items: center;
  padding: 0;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  z-index: 2;

  ${down(
    "md",
    css`
      height: ${HEADER_HEIGHT - 20}px;
    `,
  )}
`
const Fill = styled.div`
  height: ${HEADER_HEIGHT}px;
  ${down(
    "md",
    css`
      height: ${HEADER_HEIGHT - 20}px;
    `,
  )}
`
const Img = styled.img`
  height: ${HEADER_HEIGHT}px;
  ${down(
    "md",
    css`
      height: ${HEADER_HEIGHT - 20}px;
    `,
  )}
`

const PageHeader: React.FC = () => {
  const { data } = useSWR<User>("auth/me")
  const upMd = useUp("md")
  const path = usePath() || ""

  return (
    <>
      <Top>
        <x.div
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          h="100%"
        >
          {(upMd || !data?.isAdmin) && <Img src={logo} />}
          <MenuBox href="/fighters" selected={path.includes("fighters")}>
            <MenuItem>Kæmpere</MenuItem>
          </MenuBox>
          <MenuBox href="/events" selected={path.includes("events")}>
            <MenuItem>Begivenheder</MenuItem>
          </MenuBox>

          {data?.isAdmin && (
            <>
              <MenuBox href="/admin" selected={path.includes("admin")}>
                <MenuItem>Admin</MenuItem>
              </MenuBox>
              <MenuBox href="/mail" selected={path.includes("mail")}>
                <MenuItem>Mail</MenuItem>
              </MenuBox>
            </>
          )}
        </x.div>
        <x.div
          pr={{ xs: "2", sm: "4" }}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          h="100%"
        >
          {data ? (
            <MenuBox href="/profile" selected={path.includes("profile")}>
              <MenuItem>Profil</MenuItem>
            </MenuBox>
          ) : (
            <MenuBox href="/login" selected={path.includes("login")}>
              <MenuItem>Login</MenuItem>
            </MenuBox>
          )}
        </x.div>
      </Top>
      <Fill />
    </>
  )
}

export default PageHeader
