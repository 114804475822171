let space: any = [0, 4, 8, 16, 32, 64, 128]
space.large = space[4]
space.normal = space[3]
space.small = space[2]

type mySpaces = number | "large" | "normal" | "large"

type myColors =
  | "primary"
  | "secondary"
  | "link"
  | "success"
  | "warning"
  | "error"
  | "text"
  | "disabled"
  | "border"
  | "faded"
  | "basic"

export interface ColorProps {
  color?: myColors
  bg?: myColors
}
// export interface _ThemeColors {
//   primary: CSS.ColorProperty
//   link: CSS.ColorProperty
//   success: CSS.ColorProperty
//   warning: CSS.ColorProperty
//   error: CSS.ColorProperty
//   heading: CSS.ColorProperty
//   text: CSS.ColorProperty
//   disabled: CSS.ColorProperty
//   border: CSS.ColorProperty
// }

const colors = {
  primary: "#af0917",
  secondary: "#0c8696",

  header: "#353233",

  link: "#1890ff",
  success: "#52c41a",
  warning: "#faad14",
  error: "#e84118",
  heading: "#423EA2",
  text: "#202124",
  disabled: "#f5222d",
  border: "#e0e0e0",
  muted: "#ececec",
  faded: "gray",
  basic: "#efefef",
}

export const borders = { card: `1px solid ${colors.border}` }
export const radii = {
  large: "40px",
  medium: "20px",
  small: "10px",
  card: "8px",
}
// export const breakpoints = ["40em", "52em", "64em"]
const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}
export const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64]
export const defaultTheme = {
  space,
  screens: breakpoints,
  fontSizes,
  colors,
  radii,
  borders,
  sizes: space,
}
