import React, { useState } from "react"
import Field from "../../style/Field"
import { post } from "../../utils/request"
import Button from "../../style/Button"
import { mutate } from "swr"
import { ErrorText } from "../../style/Text"

type Props = {}

const defaultState = {
  name: "",
  email: "",
  password: "",
  phoneNumber: "",
}

const Register: React.FC<Props> = () => {
  const [state, setState] = useState(defaultState)
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const handleSubmit = async (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()

    try {
      setInProgress(true)
      setError(null)
      const { email, name, password, phoneNumber } = state
      const resp = await post("auth", {
        email: email.trim(),
        name: name.trim(),
        password: password.trim(),
        phoneNumber: phoneNumber.trim(),
      })
      setInProgress(false)
      mutate("auth/me", resp, false)
    } catch (error) {
      setInProgress(false)
      setError(error)
    }
  }

  const handleChange = (name: string, e: string) => {
    setState((s) => ({ ...s, [name]: e }))
  }
  return (
    <form onSubmit={handleSubmit}>
      <h2>Opret profil</h2>
      <Field
        label="Navn (dit eget)"
        id="name"
        value={state.name}
        onChange={handleChange}
      />
      <Field
        label="Telefonnummer"
        id="phoneNumber"
        value={state.phoneNumber}
        onChange={handleChange}
      />
      <Field
        label="E-mail"
        id="email"
        value={state.email}
        onChange={handleChange}
      />
      <Field
        label="Password"
        id="password"
        type="password"
        value={state.password}
        onChange={handleChange}
      />
      {error && <ErrorText mt="3">{error.message}</ErrorText>}

      <Button
        loading={inProgress}
        mt="4"
        width="100%"
        variant="primary"
        onClick={handleSubmit}
      >
        Opret
      </Button>
    </form>
  )
}

export default Register
