import PageGrid, { Divider } from "../../style/PageGrid"
import React, { useEffect } from "react"

import LoginComponent from "../../features/auth/Login"
import { navigate } from "raviger"
import useSWR from "swr"
import { x } from "@xstyled/styled-components"

type Props = {}

const Login: React.FC<Props> = () => {
  const { data: user } = useSWR<User>("auth/me")

  useEffect(() => {
    if (user) navigate("/")
  }, [user])

  return (
    <PageGrid>
      <x.div py="6">
        <LoginComponent />
      </x.div>
      <Divider />
    </PageGrid>
  )
}

export default Login
