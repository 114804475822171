import React, { useState } from "react"
import Field from "../../style/Field"
import { post } from "../../utils/request"
import Button from "../../style/Button"
import { mutate } from "swr"
import Text, { ErrorText } from "../../style/Text"

type Props = {}

const defaultState = {
  email: "",
  password: "",
}

const Login: React.FC<Props> = () => {
  const [state, setState] = useState(defaultState)
  const [reset, setReset] = useState(false)
  const [success, setSuccess] = useState(false)
  const [resetError, setResetError] = useState<null | Error>(null)
  const [loginError, setLoginError] = useState<null | Error>(null)

  const [inProgress, setInProgress] = useState(false)

  const handleLogin = async (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    try {
      setLoginError(null)
      setInProgress(true)
      const { email, password } = state
      const resp = await post("auth/login", {
        email: email.trim(),
        password: password.trim(),
      })
      setInProgress(false)
      mutate("auth/me", resp, false)
    } catch (error) {
      setSuccess(false)
      setInProgress(false)
      setLoginError(error)
      console.log(error)
    }
  }
  const handleReset = async () => {
    try {
      setResetError(null)
      setInProgress(true)
      await post("auth/reset", { email: state.email })
      setInProgress(false)
      setSuccess(true)
      setReset(false)
    } catch (error) {
      setInProgress(false)
      setResetError(error)
      console.log(error)
    }
  }

  const handleShowReset = () => {
    setReset(true)
    setSuccess(false)
  }
  const handleHideReset = () => {
    setReset(false)
  }

  const handleChange = (name: string, e: string) => {
    setState((s) => ({ ...s, [name]: e }))
  }

  const renderResetPassword = () => {
    return (
      <div>
        <h2>Reset kodeord</h2>
        <Field
          label="E-mail"
          id="email"
          value={state.email}
          onChange={handleChange}
        />
        {resetError && <Text m="0">{resetError.message}</Text>}
        <Button
          loading={inProgress}
          width="100%"
          mt="4"
          variant="primary"
          onClick={handleReset}
        >
          Reset password
        </Button>
        <Button variant="link" onClick={handleHideReset}>
          Tilbage til login
        </Button>
      </div>
    )
  }
  const renderLogin = () => {
    return (
      <form onSubmit={handleLogin}>
        <h2>Login</h2>
        <Field
          label="E-mail"
          id="email"
          value={state.email}
          onChange={handleChange}
        />
        <>
          <Field
            label="Password"
            id="password"
            type="password"
            value={state.password}
            onChange={handleChange}
          />
          {loginError && <ErrorText>{loginError?.message}</ErrorText>}

          {success && (
            <Text textAlign="center">Reset password e-mail afsendt</Text>
          )}
          <Button
            loading={inProgress}
            width="100%"
            mt="4"
            variant="primary"
            onClick={handleLogin}
          >
            Login
          </Button>
        </>
        <Button variant="link" onClick={handleShowReset}>
          Glemt kodeord?
        </Button>
      </form>
    )
  }

  return reset ? renderResetPassword() : renderLogin()
}

export default Login
