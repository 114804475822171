import React, { useState } from "react"

import Button from "../../style/Button"
import EditUserModal from "./EditUserModal"
import Flex from "../../style/Flex"
import Loading from "../../features/Loading"
import Text from "../../style/Text"
import useSWR from "swr"
import { x } from "@xstyled/styled-components"

type Props = {}

const User: React.FC<Props> = () => {
  const { data } = useSWR<User>("auth/me")
  const [showEdit, setShowEdit] = useState(false)

  if (!data) return <Loading />
  const { name, email, phoneNumber } = data

  return (
    <>
      <x.div py="5">
        <Flex justifyContent="space-between" alignItems="center">
          <h2>Min profil</h2>
          <Button variant="link" onClick={() => setShowEdit(true)}>
            Ret profil
          </Button>
        </Flex>
        <x.div py="3">
          <Text fontSize="5" m="0">
            {name}
          </Text>
          <Text m="0" fontSize="4">
            {email}
          </Text>
          <Text m="0" fontSize="4">
            {phoneNumber}
          </Text>
        </x.div>
      </x.div>
      {showEdit && <EditUserModal onClose={() => setShowEdit(false)} />}
    </>
  )
}

export default User
