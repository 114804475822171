import styled from "@xstyled/styled-components"
import React, { useEffect, useRef, useState } from "react"

type Props = {}

const Collapse = styled.div<{ h: string }>`
  transition: height 0.3s ease-out;
  height: ${(p) => p.h};
  overflow: hidden;
`

const Collapsible: React.FC<Props> = ({ children }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [height, setHeight] = useState<string>()

  useEffect(() => {
    if (ref.current?.offsetHeight) {
      setHeight(ref.current?.offsetHeight + "px")
    } else {
      setHeight("auto")
    }
  }, [children])

  return (
    <Collapse h={height || "auto"}>
      <div ref={ref}>{children}</div>
    </Collapse>
  )
}

export default Collapsible
