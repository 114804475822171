import { SpaceProps, space, up } from "@xstyled/system"
import styled, { css } from "@xstyled/styled-components"

const PageGrid = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 2rem;

  ${up(
    "md",
    css`
      grid-template-columns: 1fr auto 1fr;
      grid-template-rows: auto;
      grid-gap: 4rem;
    `,
  )}
`

const Divider = styled.hr<SpaceProps>`
  border-color: primary;
  margin: 0;
  ${space}
`
export { Divider }
export default PageGrid
