import React, { useEffect, useState } from "react"
// import styled from '@emotion/styled'
import styled from "@xstyled/styled-components"

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40vh;
`

export const Spinner: React.FC = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])
  return (
    <div style={{ height: "20px", textAlign: "center" }}>
      {show ? "Loading" : ""}
    </div>
  )
}

const Loading: React.FC = () => {
  return (
    <Container>
      <Spinner />
    </Container>
  )
}

export default Loading
