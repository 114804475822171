const dateTimeFormat: any = new Intl.DateTimeFormat("da", {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
})
const dayMonthYear = new Intl.DateTimeFormat("da", {
  year: "numeric",
  month: "long",
  day: "numeric",
})
const dayMonth = new Intl.DateTimeFormat("da", {
  month: "long",
  day: "numeric",
})
const day = new Intl.DateTimeFormat("da", {
  month: "long",
  day: "numeric",
})

// const time = new Intl.DateTimeFormat("da", {
//   hour: "numeric",
//   minute: "numeric",
// })

const formatRange = (from: Date, to: Date): string => {
  if (from > to) return dateTimeFormat.format(to)

  if (from.getFullYear() !== to.getFullYear())
    return `${dayMonthYear.format(from)} - ${dayMonthYear.format(to)}`

  if (from.getMonth() !== to.getMonth())
    return `${dayMonth.format(from)} - ${dayMonthYear.format(to)}`

  if (from.getDate() !== to.getDate())
    return `${day.format(from)} - ${dayMonthYear.format(to)}`

  return `${dayMonthYear.format(from)}`
  // return `${dayMonthYear.format(from)} ${time.format(from)} - ${time.format(
  //   from,
  // )}`
}

export default formatRange
