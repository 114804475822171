import React, { useCallback } from "react"
import styled, { x } from "@xstyled/styled-components"

import { AiOutlineDelete } from "react-icons/ai"
import { BsFileEarmarkPlus } from "react-icons/bs"
import Button from "../style/Button"
import Input from "../style/Input"
import Label from "../style/Label"
import { useDropzone } from "react-dropzone"

export type Attachment = {
  Name: string
  Content: string
  ContentType: string
}

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0px;
  cursor: pointer;
  width: fit-content;
  :hover {
    svg {
      color: primary;
    }
  }
`

const Name = styled.span`
  transform: translateY(2px);
  margin-left: 2;
`
const Backdrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: primary;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 4px dashed;
  border-color: primary;
  border-radius: 5px;
  backdrop-filter: blur(4px);
`

const TextAreaWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`

type Props = {
  sender?: string
  subject: string
  setSubject: (subject: string) => void
  text: string
  setText: (subject: string) => void
  onSubmit: () => void
  inProgress: boolean
  onCancel?: () => void
  submitText?: string
  canSubmit?: boolean
  setFiles: (
    attachments: Attachment[] | ((attachments: Attachment[]) => Attachment[]),
  ) => void
  files: Attachment[]
}

const Textarea = styled.textarea`
  border-radius: 3;
  padding: 2;
  border: solid 1px;
  border-color: border;
  width: 100%;
`

const fileToBase64 = (blob: Blob): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = function () {
      resolve(reader.result?.toString().replace(/^data:.+;base64,/, "") || "")
    }
    reader.onerror = function (e) {
      reject(e)
    }
    reader.readAsDataURL(blob)
  })
}

const MailEditor: React.FC<Props> = ({
  sender,
  subject,
  setSubject,
  text,
  setText,
  inProgress,
  onCancel,
  onSubmit,
  canSubmit = true,
  submitText = "Send mails",
  setFiles,
  files,
}) => {
  const addFile = useCallback(
    async (file: File) => {
      const Content = await fileToBase64(file)
      const attachment: Attachment = {
        Name: file.name,
        ContentType: file.type,
        Content,
      }
      if (setFiles) setFiles((files) => [...files, attachment])
    },
    [setFiles],
  )
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      await Promise.all(acceptedFiles.map(addFile))
    },
    [addFile],
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    maxSize: 8_000_000,
    noClick: true,
    disabled: inProgress,
  })

  const removeFile = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    event.stopPropagation()
    if (setFiles)
      setFiles((files) => files.filter((_file, idx) => !(idx === index)))
  }

  const renderFile = (file: Attachment, index: number) => {
    const { Name: name } = file
    return (
      <Item key={index} onClick={(event) => removeFile(event, index)}>
        <AiOutlineDelete />
        <Name>{name}</Name>
      </Item>
    )
  }

  return (
    <>
      {sender && (
        <x.div display="flex" flexDirection="column">
          <Label htmlFor="from">Afsender</Label>
          <Input
            aria-disabled
            id="from"
            value={sender}
            onChange={(e) => () => {}}
            disabled={true}
            autoComplete="off"
          />
        </x.div>
      )}
      <x.div display="flex" flexDirection="column">
        <Label htmlFor="subject">Emne</Label>
        <Input
          id="subject"
          value={subject}
          onChange={(e) => setSubject(e.currentTarget.value)}
          disabled={inProgress}
          autoComplete="off"
        />
      </x.div>
      <x.div display="flex" flexDirection="column">
        <Label htmlFor="text">Tekst</Label>
        <TextAreaWrapper {...getRootProps()} tabIndex={-1}>
          {isDragActive && (
            <Backdrop>
              <BsFileEarmarkPlus size="40px" />
              <p>Drop fil for at vedhæfte</p>
            </Backdrop>
          )}
          <Textarea
            disabled={inProgress}
            rows={15}
            id="text"
            value={text}
            onChange={(e) => setText(e.currentTarget.value)}
          />
        </TextAreaWrapper>
        <input {...getInputProps()} />
        <div>
          {files?.map(renderFile)}
          <Button variant="link" mt="3" onClick={open} disabled={inProgress}>
            Vedhæft file
          </Button>
        </div>
      </x.div>
      <x.div mt="4" display="flex" justifyContent="flex-end">
        {onCancel && (
          <Button disabled={inProgress} variant="link" onClick={onCancel}>
            Fortryd
          </Button>
        )}
        <Button
          loading={inProgress}
          disabled={!(subject && text) || !canSubmit}
          variant="primary"
          onClick={onSubmit}
        >
          {submitText}
        </Button>
      </x.div>
    </>
  )
}

export default MailEditor
