import React from "react"
import Text from "../../style/Text"
import useSWR from "swr"
import { x } from "@xstyled/styled-components"

type Props = {}

const Fighters: React.FC<Props> = () => {
  const { data } = useSWR<Fighter[]>("users/fighters")

  const renderFighter = ({ uid, name, club, ageCategory, status }: Fighter) => {
    return (
      <x.div py="3" key={uid}>
        <Text fontSize="5" m="0">
          {name}
        </Text>
        <Text m="0" fontSize="4">
          {[status, ageCategory].filter((v) => !!v).join(", ")}
        </Text>
        <Text m="0">{club}</Text>
      </x.div>
    )
  }

  return (
    <x.div py="5">
      <h2>Kæmpere</h2>
      {data?.length === 0 && (
        <Text color="faded" textAlign="center" fontSize="3" my="5">
          Der er ingen kæmpere knyttet til din profil
        </Text>
      )}
      {data?.map(renderFighter)}
    </x.div>
  )
}

export default Fighters
