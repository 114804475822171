import React, { Suspense, useEffect } from "react"
import { ThemeProvider, x } from "@xstyled/styled-components"
import { useRedirect, useRoutes } from "raviger"

import ErrorBoundary from "./features/ErrorBoundary"
import Event from "./pages/Event"
import EventOverview from "./pages/EventOverview"
import Fighters from "./pages/Fighters"
import Footer from "./features/Footer"
import Invite from "./features/auth/Invite"
import Loading from "./features/Loading"
import Login from "./pages/Login"
import PageHeader from "./features/PageHeader"
import Persondatapolitik from "./pages/GDPR/Persondatapolitik"
import Profile from "./pages/Profile"
import { SWRConfig } from "swr"
import { createGlobalStyle } from "styled-components"
import { defaultTheme } from "./style/theme"
import { get } from "./utils/request"

const CreateEvent = React.lazy(() => import("./pages/CreateEvent"))
const CreateUpdateFighter = React.lazy(
  () => import("./pages/CreateUpdateFighter"),
)
const Admin = React.lazy(() => import("./pages/Admin"))
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"))
const RegisterPage = React.lazy(() => import("./pages/Register"))
const MailPage = React.lazy(() => import("./pages/Mail"))

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  button, input, select, textarea {margin: 0;}

  *:focus__ {
    outline: 1px dotted ${defaultTheme.colors.header};
}

  body {
    font-family: 'Roboto', arial, sans-serif;
    margin: 0;
    color: ${defaultTheme.colors.text};


    a {
      color:  ${defaultTheme.colors.primary}
    }
  }
`

const config = {
  fetcher: (url: string) => get(url),
  suspense: false,
}

const routes: Record<string, (props: Record<string, string>) => JSX.Element> = {
  "/events": () => <EventOverview />,
  "/events/create": () => <CreateEvent eventUid="__tournament_template" />,
  "/events/template": () => (
    <CreateEvent eventUid="__tournament_template" isTemplate />
  ),
  "/events/:eventUid": ({ eventUid }) => <Event eventUid={eventUid} />,
  "/events/:eventUid/edit": ({ eventUid }) => (
    <CreateEvent eventUid={eventUid} isUpdate />
  ),
  "/fighters": () => <Fighters />,
  "/fighters/create": () => <CreateUpdateFighter />,
  "/fighters/:uid": ({ uid }) => <CreateUpdateFighter uid={uid} />,
  "/connect/:invite": ({ invite }) => <Invite invite={invite} />,
  "/invite/:invite": ({ invite }) => <Invite invite={invite} />,
  "/admin": () => <Admin />,
  "/persondatapolitik": () => <Persondatapolitik />,
  "/profile": () => <Profile />,
  "/login": () => <Login />,
  "/reset/:resetToken": ({ resetToken }) => (
    <ResetPassword resetToken={resetToken} />
  ),
  "/register": () => <RegisterPage />,
  "/mail": () => <MailPage />,
}

const App: React.FC = () => {
  const routeResult = useRoutes(routes)
  useRedirect("/", "/events")

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <SWRConfig value={config}>
        <PageHeader />
        <ErrorBoundary>
          <Suspense fallback={<Loading />}>
            <x.div
              display="flex"
              justifyContent="center"
              my="4"
              minHeight="calc(100vh - 124px)"
            >
              <x.div
                w={{
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: defaultTheme.screens.lg,
                  xl: defaultTheme.screens.xl,
                }}
                h="100%"
                overflow="hidden"
                mx="2"
              >
                {routeResult || <div>Not found</div>}
              </x.div>
            </x.div>
            <Footer />
          </Suspense>
        </ErrorBoundary>
      </SWRConfig>
    </ThemeProvider>
  )
}

export default App
