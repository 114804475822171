import React, { useState } from "react"
import useSWR from "swr"
import Table from "./Table"
import Loading from "../../../features/Loading"
import SendEmail from "../SendEmail"

type Props = { uid: string; isAdmin?: boolean; eventTitle: string }

type Fighter = {
  uid: string
  name: string
  club: string
  ageCategory: string
  status: string
  birthYear?: number
  custom: { name: string; value: string }[]
  attending: boolean
  answered: boolean
  invited: boolean
}

type StatusType = "declined" | "accepted" | "notAnswered" | "all"

const Registrations: React.FC<Props> = ({ uid, isAdmin, eventTitle }) => {
  const { data } = useSWR<Fighter[]>(
    isAdmin ? `event/${uid}/admin/registrations` : `event/${uid}/registrations`,
  )
  const [show, setShow] = useState(false)
  const [type, setType] = useState<StatusType | null>(null)

  if (!data) return <Loading />
  const fighters = data?.reduce(
    (total, val) => {
      if (val.answered && val.attending) total.accepted.push(val)
      if (val.answered && !val.attending) total.declined.push(val)
      if (!val.answered) total.notAnswered.push(val)
      return total
    },
    {
      accepted: [],
      declined: [],
      notAnswered: [],
    } as { accepted: Fighter[]; declined: Fighter[]; notAnswered: Fighter[] },
  )

  const handleSendMail = (type: StatusType) => {
    setType(type)
    setShow(true)
  }
  const handleClose = () => {
    setType(null)
    setShow(false)
  }

  return (
    <>
      <Table
        fighters={fighters?.accepted}
        title="Tilmeldinger"
        onSendMail={() => handleSendMail("accepted")}
        isAdmin={isAdmin}
        eventUid={uid}
        attending={true}
        eventTitle={eventTitle}
        canDeleteFighter={true}
      />
      <Table
        fighters={fighters?.declined}
        title="Afbud"
        onSendMail={() => handleSendMail("declined")}
        isAdmin={isAdmin}
        eventUid={uid}
        attending={false}
        eventTitle={eventTitle}
        canDeleteFighter={true}
      />
      <Table
        fighters={fighters?.notAnswered}
        title="Udtaget"
        onSendMail={() => handleSendMail("notAnswered")}
        isAdmin={isAdmin}
        eventUid={uid}
        attending={false}
        eventTitle={eventTitle}
        canDeleteFighter={false}
      />

      {show && type && (
        <SendEmail eventUid={uid} onClose={handleClose} type={type} />
      )}
    </>
  )
}

export default Registrations
