import React, { useMemo } from "react"
import styled, { x } from "@xstyled/styled-components"

import { Link as A } from "raviger"
import Flex from "../style/Flex"
import logo from "../assets/logo_light.svg"

const Link = styled(A)`
  text-decoration: none;
  color: inherit;
`

const External = styled.a`
  color: inherit;
`

const Content = styled.div`
  width: 100%;
  background-color: #353233;
  display: flex;
  justify-content: center;
  color: white;
  padding-top: 5;
  align-items: center;
`
const Img = styled.img`
  height: 250px;
  margin-bottom: 4;
`
type Props = {}

const handles = [
  "💪",
  "🍍",
  "🦕",
  "🥂",
  "🍹",
  "🍸",
  "🍾",
  "🕶️",
  "🎩",
  "👑",
  "🌠",
  "🌮",
  "🍣",
]

const Footer: React.FC<Props> = () => {
  const handle = useMemo(() => {
    const max = handles.length
    return handles[Math.ceil(Math.random() * max)]
  }, [])
  return (
    <Content>
      <Flex flexDirection="column" alignItems="center">
        <External
          href="https://judo.dk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Img src={logo} />
        </External>

        <x.div textAlign="center">
          Judo Danmark · Brøndby Stadion 20 · 2605 Brøndby{" "}
        </x.div>
        <div>
          <Link href="tel:43262920">43 26 29 20</Link> ·{" "}
          <Link href="mailto:dju@dju.dk">dju@dju.dk</Link>
        </div>

        <Flex my="4">
          <div>
            <Link href="/persondatapolitik">Persondatapolitik</Link>
          </div>
          <x.div mx="2">·</x.div>
          <div>
            Made with {handle} by{" "}
            <External
              href="https://kruse.dev"
              target="_blank"
              rel="noopener noreferrer"
            >
              kruse.dev
            </External>
          </div>
        </Flex>
      </Flex>
    </Content>
  )
}

export default Footer
