import Dialog, { CloseButton, Header } from "../../style/Dialog"
import MailEditor, { Attachment } from "../../features/MailEditor"
import React, { useState } from "react"

import Button from "../../style/Button"
import { ErrorText } from "../../style/Text"
import { post } from "../../utils/request"
import useSWR from "swr"
import { x } from "@xstyled/styled-components"

type Props = {
  eventUid: string
  onClose: () => void
  type: string
}

const SendEmail: React.FC<Props> = ({ eventUid, onClose, type }) => {
  const { data: currentUser } = useSWR<User>("auth/me")
  const { data } = useSWR<{ email: string; name: string }>("config/emailsender")

  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [subject, setSubject] = useState("")
  const [text, setText] = useState("")
  const [showRecipients, setShowRecipients] = useState<boolean>(false)
  const [files, setFiles] = useState<Attachment[]>([])

  const URL = `event/${eventUid}/mail/${type}`
  const { data: recipients } = useSWR<string[]>(showRecipients ? URL : null)

  const handleSubmit = async () => {
    try {
      setError(null)

      setInProgress(true)
      await post(URL, {
        subject,
        text: text.replace(/\r?\n/g, "<br />"),
        attachments: files,
      })
      setInProgress(false)
      onClose()
    } catch (error) {
      setInProgress(false)
      setError(error)
    }
  }

  return (
    <Dialog aria-label="send mail" onDismiss={inProgress ? undefined : onClose}>
      <x.div display="flex" justifyContent="space-between">
        <Header>Send email</Header>
        {!inProgress && <CloseButton onClick={onClose} />}
      </x.div>

      <Button
        ml="2"
        loading={showRecipients && !recipients}
        onClick={() => setShowRecipients((f) => !f)}
        variant="link"
      >
        {showRecipients ? "Skjul modtagere" : "Vis modtagere"}
      </Button>
      {showRecipients && recipients && (
        <x.div p="3">
          {recipients?.map((r, index) => (
            <div key={index}>{r}</div>
          ))}
        </x.div>
      )}
      <x.div p="3">
        <MailEditor
          sender={
            currentUser?.email.endsWith("@judo.dk")
              ? currentUser.email
              : data?.email
          }
          setSubject={setSubject}
          subject={subject}
          setText={setText}
          text={text}
          inProgress={inProgress}
          onSubmit={handleSubmit}
          onCancel={onClose}
          files={files}
          setFiles={setFiles}
        />
        {error && <ErrorText>{error?.message}</ErrorText>}
      </x.div>
    </Dialog>
  )
}

export default SendEmail
