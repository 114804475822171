import "@reach/dialog/styles.css"

import { DialogContent, DialogOverlay, DialogProps } from "@reach/dialog"
import styled, { css } from "@xstyled/styled-components"

import { MdClose } from "react-icons/md"
import React from "react"
import { up } from "@xstyled/system"

const StyledCloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: #5e5f61;
  cursor: pointer;
  transition: color 300ms;
  :hover {
    color: black;
  }
`
const Overlay = styled(DialogOverlay)`
  z-index: 5;
`
// const Content = styled.div`
const Content = styled(DialogContent)`
  width: 100%;
  height: 100%;
  margin: 0;
  background: white;
  padding: 0;

  ${up(
    "md",
    css`
      min-width: 600px;
      max-width: 60vw;
      width: fit-content;
      margin: 10vh auto;
      background: white;
      outline: none;
      height: auto;
    `,
  )}
`
export const Header = styled.h2`
  margin: 3;
  font-weight: 400;
`

export const CloseButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <StyledCloseButton onClick={onClick}>
      <MdClose aria-hidden size={25} />
    </StyledCloseButton>
  )
}

const Dialog: React.FC<DialogProps> = ({ children, ...restProps }) => {
  return (
    <Overlay {...restProps}>
      <Content aria-label={restProps["aria-label"]}>{children}</Content>
    </Overlay>
  )
}

export default Dialog
