import React, { useState } from "react"
import styled, { x } from "@xstyled/styled-components"

import Button from "../../style/Button"
import DeleteRegistrationDialog from "./DeleteRegistrationDialog"
import { FieldResponseType } from "../../customTypes"
import InfoBox from "../../style/InfoBox"
import Register from "./Register"
import Text from "../../style/Text"
import useSWR from "swr"

type Props = { eventUid: string }

type CustomField = { name: string; value: string }
type ExtendedFighter = Fighter & {
  invited: boolean
  answered: boolean
  attending?: boolean
  custom: CustomField[]
  birthYear?: number
}

const Status = styled.h3`
  margin: 0;
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 1;
`
const FighterName = styled.h2<{ lineThrough: boolean }>`
  margin: 0;
  font-weight: normal;
  font-size: 3;
  text-decoration: ${(p) => (p.lineThrough ? "line-through" : "none")};
`

const MyFighters: React.FC<Props> = ({ eventUid }) => {
  const { data: event } = useSWR<Tournament>(`event/${eventUid}`)

  const { data: fighters, mutate } = useSWR<ExtendedFighter[]>(
    `event/${eventUid}/fighters`,
  )
  const [fighterUid, setFighterUid] = useState<null | string>(null)
  const [responseType, setResponseType] = useState<null | FieldResponseType>(
    null,
  )
  const [deleteFighter, setDeleteFighter] = useState<string | null>(null)

  const openModal = (fighterUid: string, responseType: FieldResponseType) => {
    setFighterUid(fighterUid)
    setResponseType(responseType)
  }
  const closeModal = () => {
    mutate()
    setFighterUid(null)
    setResponseType(null)
  }

  const renderButtons = (uid: string) => {
    if (event && event.close < new Date())
      return (
        <Text textTransform="uppercase" m="2">
          Lukket
        </Text>
      )

    return (
      <>
        <Button
          p="0"
          variant="link"
          onClick={() => openModal(uid, FieldResponseType.accept)}
        >
          Tilmeld
        </Button>
        {event?.answerRequired && (
          <Button
            px="0"
            ml="3"
            variant="link"
            onClick={() => openModal(uid, FieldResponseType.decline)}
          >
            Frameld
          </Button>
        )}
      </>
    )
  }

  const renderFighter = (fighter: ExtendedFighter) => {
    const { attending, name, uid, answered } = fighter
    return (
      <InfoBox my="3" key={uid}>
        {answered && <Status>{attending ? "Tilmeldt" : "Frameldt"}</Status>}
        <FighterName lineThrough={answered && !attending}>{name}</FighterName>
        {answered ? (
          <>
            <x.div color="faded" m="0" pl="2">
              {fighter.custom.map((f) => (
                <div key={f.name}>
                  {`${f.name}: `} <i>{f.value}</i>
                </div>
              ))}
            </x.div>
            {event && event.close > new Date() && (
              <x.div>
                <Button variant="link" onClick={() => setDeleteFighter(uid)}>
                  {`Slet ${attending ? "tilmelding" : "framelding"}`}
                </Button>
              </x.div>
            )}
            {deleteFighter === uid && (
              <DeleteRegistrationDialog
                attending={attending}
                tournamentName={event?.title || ""}
                eventUid={eventUid}
                fighterUid={uid}
                fighterName={name}
                onClose={() => setDeleteFighter(null)}
              />
            )}
          </>
        ) : (
          renderButtons(uid)
        )}
      </InfoBox>
    )
  }

  return (
    <x.div my="5">
      {fighters?.map(renderFighter)}
      {fighterUid && eventUid && responseType && (
        <Register
          fighterUid={fighterUid}
          eventUid={eventUid}
          onClose={closeModal}
          responseType={responseType}
        />
      )}
    </x.div>
  )
}

export default MyFighters
