import PageGrid, { Divider } from "../../style/PageGrid"
import React, { useEffect } from "react"

import Button from "../../style/Button"
import Fighters from "./Fighters"
import User from "./User"
import { navigate } from "raviger"
import { post } from "../../utils/request"
import useSWR from "swr"

type Props = {}

const Profile: React.FC<Props> = () => {
  const { error } = useSWR<User>("auth/me")

  const handleLogout = async () => {
    await post("auth/logout")
    window.location.replace("/")
  }

  useEffect(() => {
    if (error) navigate("/login")
  }, [error])

  return (
    <>
      <Button onClick={handleLogout}>Log ud</Button>
      <PageGrid>
        <User />
        <Divider />
        <Fighters />
      </PageGrid>
    </>
  )
}

export default Profile
