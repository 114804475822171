import { Link as A } from "raviger"
import styled from "@xstyled/styled-components"

const Grid = styled.div<{ columns: string }>`
  display: grid;
  grid-template-columns: ${(p) => p.columns};
  grid-template-rows: auto;
  align-items: center;
  white-space: nowrap;
  overflow: auto;
`
const Item = styled.div`
  border-bottom: 1px solid #f2f2f2;
  height: 100%;
  display: flex;
  align-items: center;
  height: 50px;
`
const ItemLink = styled(A)<{ end?: number }>`
  border-bottom: 1px solid #f2f2f2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.end ? "flex-end" : "default")};
  height: 50px;
  /* color: #62646b; */
  color: text;
  text-decoration: none;
`

const Header = styled.h3`
  margin: 0;
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 2;
`

export { Grid, Item, Header, ItemLink }
