import React from "react"
import styled from "@xstyled/styled-components"

import {
  AiOutlineFilePdf,
  AiOutlineFileWord,
  AiOutlineFilePpt,
  AiOutlineFileExcel,
  AiOutlineFileImage,
  AiOutlineFile,
} from "react-icons/ai"

type Props = { files: EventFile[] }

const A = styled.a`
  color: black;
  display: flex;
  align-items: center;
  padding: 8px 0px;
  text-decoration: none;
  width: fit-content;

  :hover {
    text-decoration: underline;
  }
  svg {
    color: primary;
  }
`
const Name = styled.span`
  transform: translateY(2px);
  margin-left: 2;
`

const getExtension = (filename: string): string => {
  return (
    filename.substring(filename.lastIndexOf(".") + 1, filename.length) || ""
  )
}

const getIcon = (ext: string) => {
  if (ext.match(/doc|docx/gi)) return <AiOutlineFileWord size="20px" />
  if (ext.match(/pdf/gi)) return <AiOutlineFilePdf size="20px" />
  if (ext.match(/ppt|pptx/gi)) return <AiOutlineFilePpt size="20px" />
  if (ext.match(/xls|xlsx/gi)) return <AiOutlineFileExcel size="20px" />
  if (ext.match(/png|jpeg|jpg|gif/gi)) return <AiOutlineFileImage size="20px" />

  return <AiOutlineFile size="20px" />
}

const Files: React.FC<Props> = ({ files }) => {
  const renderFile = (file: EventFile) => {
    const { name, uid } = file
    const ext = getExtension(name)
    return (
      <A
        key={uid}
        target="_blank"
        rel="noopener noreferrer"
        href={`/api/file/${uid}/${name}`}
      >
        {getIcon(ext)}
        <Name>{name.replace(/\.[^/.]+$/, "")}</Name>
      </A>
    )
  }

  return <>{files.map(renderFile)}</>
}

export default Files
