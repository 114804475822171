import { Link as A, navigate } from "raviger"
import React, { ReactElement, useState } from "react"
import { Table, Th, Tr } from "../../style/table"
import { useDown, x } from "@xstyled/styled-components"

import Button from "../../style/Button"
import Collapsible from "../../style/Collapsible"
import { FiChevronRight } from "react-icons/fi"
import Flex from "../../style/Flex"
import Text from "../../style/Text"
import Title from "../../style/Title"
import formatDateTime from "../../utils/formatDateTime"
import formatRange from "../../utils/formatRange"
import styled from "@xstyled/styled-components"

const EventTitle = styled.h3`
  margin: 0 1rem 0 0;

  font-size: 3;
`
const EventLink = styled(A)`
  color: text;
  text-decoration: none;
`
type Props = {
  title: string
  events: Tournament[]
  forceShowAll: boolean
}

const renderMobileEvent = ({
  uid,
  title,
  close,
  end,
  start,
}: Tournament): ReactElement => {
  return (
    <EventLink key={uid} href={`/events/${uid}`}>
      <x.div borderBottom="1px solid" px="2" pb="2" pt="3" borderColor="border">
        <Flex alignItems="center" flexWrap="wrap">
          <EventTitle>{title}</EventTitle>
          <Text fontSize="2" my="0">
            {formatRange(start, end)}
          </Text>
        </Flex>
        <Text m="0">{`Tilmeldingsfrist ${formatDateTime(close)}`}</Text>
      </x.div>
    </EventLink>
  )
}

const renderDesktopEvent = ({
  uid,
  title,
  close,
  end,
  start,
}: Tournament): ReactElement => {
  const startDate = new Date(start)
  const endDate = new Date(end)

  return (
    <Tr key={uid} cursor="pointer" onClick={() => navigate(`/events/${uid}`)}>
      <td>{formatRange(startDate, endDate)}</td>
      <td>{title}</td>
      <td>{formatDateTime(close)}</td>
      <td>
        <FiChevronRight />
      </td>
    </Tr>
  )
}

const Category: React.FC<Props> = ({ title, events, forceShowAll }) => {
  const downMd = useDown("md")

  const [showAll, setShowAll] = useState<boolean>(forceShowAll)

  if (!events.length) return null
  const eventsToShow = showAll ? events : events.slice(0, 8)

  return (
    <x.div py={{ xs: 3, md: 2 }}>
      <Title>{title}</Title>
      <Collapsible>
        {downMd ? (
          <>{eventsToShow.map(renderMobileEvent)}</>
        ) : (
          <x.div overflow="hidden">
            <Table>
              <thead>
                <tr>
                  <Th width="30%">Dato</Th>
                  <Th width="50%">Begivenhed</Th>
                  <Th>Tilmeldingsfrist</Th>
                  <Th />
                </tr>
              </thead>
              <tbody>{eventsToShow?.map(renderDesktopEvent)}</tbody>
            </Table>
          </x.div>
        )}
      </Collapsible>
      {!forceShowAll && events.length > 8 && (
        <x.div display="flex" justifyContent="center" mt="3">
          <Button variant="link" onClick={() => setShowAll(!showAll)}>
            {showAll ? "Skjul" : "Vis alle"}
          </Button>
        </x.div>
      )}
    </x.div>
  )
}

export default Category
