import styled from "@xstyled/styled-components"
import { space, layout, SpaceProps, LayoutProps } from "@xstyled/system"

const Input = styled.input<{ error?: boolean } & LayoutProps & SpaceProps>`
  ${space}
  ${layout}
  border-radius: 3;
  padding: 2;
  border: solid 1px;
  border-color: ${(p) =>
    p.error ? p.theme.colors.error : p.theme.colors.border};
  box-sizing: border-box;
`

export default Input
