import styled, { x } from "@xstyled/styled-components"

import { Divider } from "../../style/PageGrid"
import Files from "./Files"
import Flex from "../../style/Flex"
import InfoBox from "../../style/InfoBox"
import { Link } from "raviger"
import Linkify from "../../utils/Linkify"
import Loading from "../../features/Loading"
import MyFighters from "./MyFighters"
import React from "react"
import Registrations from "./Registrations"
import Text from "../../style/Text"
import Title from "../../style/Title"
import formatDateTime from "../../utils/formatDateTime"
import formatRange from "../../utils/formatRange"
import useSWR from "swr"

type Props = { eventUid: string }
const Des = styled.p`
  white-space: pre-wrap;
`

const Event: React.FC<Props> = ({ eventUid }) => {
  const { data } = useSWR<Tournament>(`event/${eventUid}`)
  const { data: user } = useSWR<User>("auth/me")

  if (!data) return <Loading />

  const { title, start, end, close, files, category, coaches } = data
  return (
    <div>
      <x.div display="flex" alignItems="center">
        <Title>{title}</Title>
      </x.div>
      <x.div display="flex" flexDirection={{ xs: "column", md: "row" }}>
        <Flex>
          <Divider />
          <Text my={{ xs: "2", md: "0" }} mx="3">
            {formatRange(start, end)}
          </Text>
        </Flex>
        <Flex>
          <Divider />
          <Text my={{ xs: "2", md: "0" }} mx="3">
            Tilmeldingsfrist {formatDateTime(close)}
          </Text>
        </Flex>
        {!!category.length && (
          <Flex>
            <Divider />
            <Text my={{ xs: "2", md: "0" }} mx="3">
              {category.join(", ")}
            </Text>
          </Flex>
        )}
        {!!coaches.length && (
          <Flex>
            <Divider />
            <Text my={{ xs: "2", md: "0" }} mx="3">
              {`${coaches.length > 1 ? "Trænere" : "Træner"}: ${coaches
                .map((c) => c.name)
                .join(", ")}`}
            </Text>
          </Flex>
        )}
        {user?.isAdmin && (
          <Flex>
            <Divider />
            <Text my={{ xs: "2", md: "0" }} mx="3">
              <Link href={`/events/${eventUid}/edit`}>Redigere</Link>
            </Text>
          </Flex>
        )}
      </x.div>

      {data?.description && (
        <InfoBox my="3" bg="antiquewhite">
          <Linkify>
            <Des dangerouslySetInnerHTML={{ __html: data?.description }} />
          </Linkify>
        </InfoBox>
      )}
      <Files files={files} />

      {user && <MyFighters eventUid={eventUid} />}
      <Registrations
        uid={eventUid}
        isAdmin={user?.isAdmin}
        eventTitle={title}
      />
    </div>
  )
}

export default Event
